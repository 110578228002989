import React from 'react'
import { graphql } from 'gatsby'
import LocatableDetail from "../components/locatable-detail"

export default ({ data }) => {
  const activities = data.allDatoCmsActivity.edges.flatMap(e => e.node).filter(a => a.location && a.location.building && a.location.building.id === data.locatable.id)
  const services = data.allDatoCmsService.edges.flatMap(e => e.node).filter(a => a.locations && a.locations.find(l => l.building && l.building.id === data.locatable.id))
  const vendors = data.allDatoCmsVendor.edges.flatMap(e => e.node).filter(a => a.locations && a.locations.find(l => l.building && l.building.id === data.locatable.id))
  return (
    <LocatableDetail locatable={data.locatable} settings={data.datoCmsWebsiteLayout} vendors={vendors} services={services} activities={activities} />
  )
}

export const query = graphql`
  query BuildingQuery($id: String!) {
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    allDatoCmsActivity {
      edges {
        node {
          ...ActivityFields
        }
      }
    }
    allDatoCmsService {
      edges {
        node {
          ...ServiceFields
        }
      }
    }
    allDatoCmsVendor {
      edges {
        node {
          ...VendorFields
        }
      }
    }
    locatable: datoCmsBuilding(id: { eq: $id }) {
      id
      title
      summary
      description
      itemSponsors {
        ...Sponsor
      }
      heroImage {
        url
        fluid(maxWidth: 2800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      subcategories {
        title
        heroTitleBlock {
          ...HeroTitleBlock
        }
        sponsors {
          ...Sponsor
        }
        landingPageDiscoverMore {
          ...DiscoverMore
        }
      }
      sponsors {
        ...Sponsor
      }
      model {
        apiKey
      }
      pageHeader {
        ...HeroTitleBlock
      }
    }
  }
`
